<template>
  <div class="pass-audit-box">
    <van-nav-bar
      title="绑定公司"
      @click-left="onClickLeft"
    />
    <div class="pass-audit-con">
      <div class="pass-audit">
        <img src="../../assets/positionMgt/pass_audit.png" alt />
        <i>认证成功！</i>
        <p>恭喜您！公司认证已审核通过！赶快去发布职位吧！</p>
        <div class="go-release-box">
          <van-button type="info" plain round block @click="goHome"
            >去首页</van-button
          >
          <van-button type="info" round block @click="goRelease"
            >发布职位</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'noLegalize',
    data() {
      return {};
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1);
      },
      // 进入应用，跳过当前审核阶段
      joinNext () {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = JSON.parse(userInfo);
        this.$http.put(`/selfhelpOfficialAccounts/relation/updateRealtionState/${userInfo.selfhelpCompanyApproveVO.id}`)
      },
      goHome() {
        this.joinNext()
        this.$router.push('/positionMgt/index');
      },
      goRelease() {
        this.joinNext()
        this.$router.push({ path: '/release/index', query: { type: 'add' } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pass-audit-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .header {
      height: 44px;
    }
    .pass-audit-con {
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: center;
      .pass-audit {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-top: 50px;
          height: 90px;
          width: 90px;
        }
        i {
          height: 18px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: 12px;
          font-style: normal;
        }
        p {
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          margin-top: 10px;
          color: #666666;
        }
        .go-release-box {
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          padding: 0 34px;
          .van-button{
            margin: 0 7.5px;
          }
        }
      }
    }
  }
</style>
